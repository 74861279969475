import './index.css';
import { Parallax, ParallaxLayer} from '@react-spring/parallax'
import { useRef } from 'react';




function App() {

  const parallax = useRef(null);

  const scrollToSection = (offset) => () => {
    if (parallax && parallax.current) {
      parallax.current.scrollTo(offset);
    }
  };

  return (
    <div className='App'>
      

      <Parallax pages={5} ref={parallax}>
        <ParallaxLayer offset={0} speed={0.5} className='nav-layer'>
          <div className='nav'>
            <div className='nav-buttons'>
              <button className='nav-button' onClick={scrollToSection(0)}>Home</button>
              <button className='nav-button' onClick={scrollToSection(0.8)}>About me</button>
              <button className='nav-button' onClick={scrollToSection(1.99)}>My skills</button>
              <button className='nav-button' onClick={scrollToSection(3)}>My work</button>
            </div>
            <div class="custom-shape-divider-top-1717945405">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                  <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                  <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
              </svg>
          </div>
          </div>
          
        </ParallaxLayer>


        <ParallaxLayer offset={0} speed={0}>
          <div className='para1'>
            
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={0.35} speed={0.5}>
          <div className='contentDiv'>
            <div class="title">
                <span class="first-word">Nick </span>
                <span className='second-word'>Schlederer</span>
                <div class="flashing-underscore"> </div>
              </div>
            <div class='wide-box'>Computer Scientist   and   Machine learning student</div>
            <div style={{marginTop:'5%'}} class='wide-box'>Contact me: nicholasschlederer@gmail.com</div>

          </div>

          </ParallaxLayer>
        
        <ParallaxLayer  offset={0.8} speed={0}>
          <div class="custom-shape-divider-top-1717947413">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                  <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                  <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
              </svg>
          </div>
          <div className='para2'>
            <div class="title-p2">
                <span class="first-word">About </span>
                <span className='second-word'>me</span>
                <div class="flashing-underscore"> </div>
            </div>
          </div>
         </ParallaxLayer>

        <ParallaxLayer offset={2} speed={0} >
          <div class="custom-shape-divider-top-1717948327">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                  <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                  <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
              </svg>
          </div>

          <div className='para3'>
            <div class="title-p3">
                  <span class="first-word">My </span>
                  <span className='second-word'>Skills</span>
                  <div class="flashing-underscore"> </div>
              </div>
            </div>
        </ParallaxLayer>

        <ParallaxLayer offset={3} speed={0} >

          <div className='para4'>
            <div class="title-p3">
                  <span class="first-word">My </span>
                  <span className='second-word'>work</span>
                  <div class="flashing-underscore"> </div>
              </div>
            </div>
        </ParallaxLayer>





        <ParallaxLayer offset={1} speed={0.1}>
          <div className="img-Box" style={{ marginLeft: '5%', width:'30vw',height:'40%'}}>
            <img alt='Profile' className='box-img'  src='test.png' style={{maxHeight:'100%'}}/>
            <p>
              My name is <b>Nick Schlederer</b> and i am a passionate computer science student, motivated to broaden my understanding of advanced ideas through the workforce
            </p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.1}>
          <div class="sticky-box" style={{ marginLeft: '40%'}}>
          <p className='bubble-text'> I am currently studying at UTS in my penultimate year of <b>Computer Science (Honours)</b></p>
           
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.1} style={{ marginLeft: '65%'}}>
          <div class="sticky-box">
          <p className='bubble-text'>I am seeking an internship opportunity in the area of <b>machine learning</b> or other <b>Development opportunities</b>.</p>
            
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1.25} speed={0.1}>
          <div class="sticky-box" style={{ marginLeft: '40%'}}>
          <p className='bubble-text'>I pride myself on my nuanced understanding of the simbiotic relationship of Business and Technology</p>
            
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1.5} speed={0.1}>
          <div class="sticky-box" style={{ marginLeft: '40vw'}}>
          <img alt='Profile' className='box-img'  src='JBlogo.png'/>
          <p className='bubble-text'>I am currently working in sales at<br></br> <b>JB HI FI</b></p>
            
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1.25} speed={0.1}>
          <div class="sticky-box" style={{ marginLeft: '65vw'}}>
            <p className='bubble-text'>Resumes are boring..<br></br>
            <br></br>
            I wanted a more <b>exciting</b> way to display my abilities
            </p>
          </div>
        </ParallaxLayer>


        <ParallaxLayer offset={1.5} speed={0.1}>
          <div className="sticky-box" style={{ marginLeft: '5vw',maxWidth:'30vw', maxHeight:'20%'}}>
            <img alt='Profile' className='box-img'  src='UTSlogo.png'/>
            <p style={{width:'20vw'}}>
              <strong>The boring stuff...</strong><br></br>
              <br></br>
              <b>GPA: </b>5.48<br></br>
              <b>WAM: </b>72.62<br></br>
              <b></b>

            </p>
          </div>
        </ParallaxLayer>





        <ParallaxLayer offset={1.5} speed={0.1}style={{ marginLeft: '65%', maxWidth:'30%',width:'auto'}}>
          <div className="sticky-box">
            <p style={{width:'20vw'}}>
              I built this website as a way to challenge myself in ways i felt University <b>didnt.</b>
              <br></br>
              <br></br>
              A week ago i hardly knew what react was, but i have always learnt best on the fly, problem solving as i go
            </p>
          </div>
        </ParallaxLayer>







        <ParallaxLayer offset={2.25} speed={0.5}>
          <div class="skill-box" style={{ marginLeft: '10%'}}>
            <h3>Programming languages</h3>
            <ul>
              <li>Python</li>
              <li>C++</li>
              <li>JavaScript</li>
              <li>Java</li>
              <li>Swift</li>
              <li>React</li>
            </ul>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.25} speed={0.6} >
          <div class="skill-box"style={{ marginLeft: '42.5%'}}>
            <h3>Software tools</h3>
            <ul>
              <li>Tableau</li>
              <li>Knime</li>
              <li>CVAT</li>
              <li>GitHub</li>
            </ul>
          </div>
        </ParallaxLayer>


        <ParallaxLayer offset={2.25} speed={0.57} >
          <div class="skill-box"style={{ marginLeft: '75%'}}>
            <h3>AI experience</h3>
            <ul>
              <li>Custom models</li>
              <li>Creating datasets</li>
              <li>Image classification</li>
              <li>Object detection</li>
              <li>Fuzzy Neural networks</li>
              <li>Forecasting and regression</li>
            </ul>
          </div>
        </ParallaxLayer>

        




        <ParallaxLayer offset={2.6} speed={1.2} >
          <div className="icon-box" style={{ marginLeft: '10%'}}>
            
            <h3>High Distinction</h3>
            <p>Natural language processing</p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2.6} speed={0.9} >
          <div className="icon-box" style={{ marginLeft: '25%'}}>
            <h3>High Distinction</h3>
            <p>AI/Analytics capstone</p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2.6} speed={1} >
          <div className="icon-box" style={{ marginLeft: '45%'}}>
            <h3>High Distinction</h3>
            <p>Database fundamentals</p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2.6} speed={0.9} >
          <div className="icon-box" style={{ marginLeft: '65%'}}>
            <h3>High Distinction</h3>
            <p>Computer Science Studio 2</p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2.6} speed={1.2} >
          <div className="icon-box" style={{ marginLeft: '80%'}}>
            <h3>High Distinction</h3>
            <p>Technology Research preparation</p>
          </div>
        </ParallaxLayer>






        <ParallaxLayer offset={2.8} speed={1.4} >
          <div className="icon-box" style={{ marginLeft: '65%'}}>
            <h3>Distinction</h3>
            <p>Data Visualisation and Visual Analytics</p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.8} speed={1.3} >
          <div className="icon-box" style={{ marginLeft: '45%'}}>
            <h3>Distinction</h3>
            <p>Deep learning and Convolutional Neural Networks</p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.8} speed={1.2}>
          <div className="icon-box" style={{ marginLeft: '25%'}}>
            <h3>Distinction</h3>
            <p>Data structures and Algorithms</p>
          </div>
        </ParallaxLayer>








        <ParallaxLayer offset={2.99} speed={2}>
          <div className="badge-box" style={{ marginLeft: '5%'}}>
            <img src={'colabLogo.png'} alt='uh oh' className='icon'></img>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.99} speed={1.9}>
          <div className="badge-box" style={{ marginLeft: '20%'}}>
            <img src={'JSlogo.png'} alt='uh oh' className='icon'></img>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.99} speed={1.8}>
          <div className="badge-box" style={{ marginLeft: '35%'}}>
            <img src={'pycharmLogo.png'} alt='uh oh' className='icon'></img>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.99} speed={1.8}>
          <div className="badge-box" style={{ marginLeft: '55%'}}>
            <img src={'vscodeLogo.png'} alt='uh oh' className='icon'></img>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.99} speed={1.9}>
          <div className="badge-box" style={{ marginLeft: '75%'}}>
            <img src={'pythonLogo.png'} alt='uh oh' className='icon'></img>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.99} speed={2}>
          <div className="badge-box" style={{ marginLeft: '85%'}}>
            <img src={'githubLogo.png'} alt='uh oh' className='icon'></img>
          </div>
        </ParallaxLayer>












        

        <ParallaxLayer offset={3.2} speed={1.2} className='proj-layer' style={{ width: '30vw', left: '50%' }}>
          <div class="project-box" style={{marginLeft: '10%'}}>
            <img src="proj2demo.png" alt="" class="project-image"></img>
              <p class="project-description">Solar energy forecasting and mapping using NASA's POWER dataset API</p>
            <a href="https://github.com/NickSchlederer/SolarEnergyForecasting" class="github-button">
              <img src="smallGitIcon.png" alt="GitHub Icon" class="github-icon"></img>
            </a>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={3.4} speed={0.9} className='proj-layer' style={{ width: '30vw', left: '5%' }}>
          <div class="project-box" style={{marginLeft: '10%'}}>
            <img src="proj1demo.png" alt="" class="project-image"></img>
            <div class="project-description">
              <p> YoloV7 used to detect clothing items in real time using the webcam, trained on a custom datset made with CVAT</p>
            </div>
            <a href="https://github.com/NickSchlederer/YoloV7ClothingClassifier" class="github-button">
              <img src="smallGitIcon.png" alt="GitHub Icon" class="github-icon"></img>
            </a>
          </div>
        </ParallaxLayer>


        <ParallaxLayer offset={3.9} speed={1.2} className='proj-layer' style={{ width: '30vw', left: '50%' }}>
          <div class="project-box" style={{marginLeft: '10%'}}>
            <img src="proj3demo.png" alt="" class="project-image"></img>
            <div class="project-description">
              <p>Dashcam motorcycle detection software using Yolo object detection</p>
            </div>
            <a href="https://github.com/NickSchlederer/YoloV7-CustomData" class="github-button">
              <img src="smallGitIcon.png" alt="GitHub Icon" class="github-icon"></img>
            </a>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={4} speed={0.9} className='proj-layer' style={{ width: '30vw', left: '5%' }}>
          <div class="project-box" style={{marginLeft: '10%'}}>
            <img src="proj4demo.png" alt="" class="project-image"></img>
            <div class="project-description">
              <p>Sentiment Analysis using preprocessing and RNN for text analysis.</p>
            </div>
            <a href="https://github.com/NickSchlederer/NaturalLanguageProcessing_Assignment3" class="github-button">
              <img src="smallGitIcon.png" alt="GitHub Icon" class="github-icon"></img>
            </a>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={4} speed={1.1} className='proj-layer' style={{ width: '30vw', left: '50%' }}>
          <div class="project-box" style={{marginLeft: '10%'}}>
            
            <div class="project-description">
              <p>This website was a small project to test my front end abilities, however i am still by all means back end focused!</p>
            </div>
            <a href="https://github.com/NickSchlederer/NaturalLanguageProcessing_Assignment3" class="github-button">
              <img src="smallGitIcon.png" alt="GitHub Icon" class="github-icon"></img>
            </a>
          </div>
        </ParallaxLayer>















      </Parallax>


    </div>
  );
}

export default App;
